import React, { ReactNode } from "react";
import AccordionComponent from "src/components/AccordionComponent";

// import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import styled from "styled-components";
import { FaQuora } from "react-icons/fa";

export const FAQ_TAMPLATES_MALE: { title: ReactNode; content: ReactNode }[] = [
  {
    title: "매칭의 절차는 어떻게 되나요?",
    content:
      " 멤버십 가입 이후 라운드 일정을 담당자에게 알려주시면 해당 일정에 라운드가 가능한 서포터즈 분을 안내해드립니다. \n\n 라운드 전에 사전미팅을 진행하시고 이후 두 분 모두 매칭을 희망 하시게 되면 매칭이 확정됩니다.",
  },
  {
    title: "사전 미팅은 무엇인가요?",
    content:
      " 매칭 전, 두 분이서 가볍게 만나는 자리입니다. \n\n 골프장에서 바로 만나 발생 할 수 있는 미스 매칭을  줄이기 위하여 시행하고 있으며,  사전미팅은 점심식사, 캐주얼한 저녁, 티타임 등으로 진행됩니다. \n\n 사전미팅 진행에 대한 경비는 초청자가 부담 하시는것을 원칙으로 하고 있습니다.",
  },
  {
    title: "서포터즈분들은 어떤 분들이신가요?",
    content:
      " 티샷에서 자체적으로 모집하고 필터링 한 20대~50대의 아마추어 여성분들이십니다. \n\n 직장인/주부/자영업자 등 다양한 직종의 여성분들이시고 선발 기준은 외모/골프실력/매너 3가지 조건으로 선별하였습니다.",
  },
  {
    title: "서포터즈분들에게 별도의 비용을 지불하나요?",
    content:
      " 초청자는 초청 경비를 부담하시기에 서포터즈분들에게  별도의  비용을 지불하지 않으셔도 됩니다. \n\n 추천드리는 부분은 서포터즈분이 마음에 드셨다면 차비정도 더 챙겨 주시는 정도면 충분합니다.",
  },
  {
    title: "초청 경비는 어떻게 되나요?",
    content:
      " 초청의 기본은 경비를 초청자께서 지원하시는게 일반적입니다. \n\n 서포터즈 분들은 해당 경비를 지원 받는 것을 기준으로 매칭을 하시기에 초청자께서 경비를 부담하지 않는 일반 조인이나 초청은 저희 매칭과는 별개이오니 이점 착오 없으시길 말씀 드립니다.",
  },
  {
    title: "국내골프 투어, 해외골프 투어도 진행 가능한가요?",
    content:
      " 국내, 해외골프 투어도 매칭 진행은 가능합니다. \n\n 하지만 담당자가 추천 드리는 부분은 국내에서 라운드를 자주 함께하시고, 서로 친해진 이후에 해당 서포터즈분과 국내골프투어나 해외골프투어를 가시기를 추천드립니다.",
  },
];
export const FAQ_TAMPLATES_FEMALE: { title: ReactNode; content: ReactNode }[] = [
  {
    title: "서포터즈가 무엇인가요?",
    content:
      " 티샷 서포터즈 모집에 지원해주신 여성분들 중 인터뷰와 프로필 내용을 기준으로 선발한 아마추어 여성 골퍼분들을 지칭하는 말입니다. \n\n 서포터즈 분들께는 양질의 무료라운드 초청 안내를 카카오톡으로 안내해 드리고 있습니다.",
  },
  {
    title: "사전 미팅은 무엇인가요?",
    content:
      " 라운드 전에 초청자와 서포터즈 두 분께서 만나는 가벼운 미팅 자리입니다. \n\n 일반적으로 점심이나 캐주얼한 저녁식사, 티타임 등으로 진행됩니다. 경비는 초청자께서 지불 하시는 것이 원칙 입니다.",
  },
  {
    title: "초청자분들은 어떤 분들인가요?",
    content:
      " 초청자분들은 티샷 프리미엄 매칭 서비스에 가입하신 분들이시며, 티샷의 인증 절차를 통하여 신분을 확인한 분들입니다. \n\n 대부분 법인 대표, 의사, 대기업 임원 등 사회적으로 인정 받는 분들이시며 라운드 외에도 네트웍을 형성하시면 여러모로 도움이 되실 분들입니다.",
  },
  {
    title: "매칭은 어떻게 이루어지나요?",
    content:
      " 무료라운드 초청건을 서포터즈분들에게 안내 드린 이후 해당 일정에 가능하신 서포터즈분들을 확인합니다. \n\n 이후 초청자분과 의견을 나누고 사전미팅을 을 진행을 하게 됩니다. \n\n 사전 미팅 이후 초청자분과 서포터즈 두 분 모두 매칭을 희망하셔야 매칭이 성사됩니다.",
  },
  {
    title: "초청건의 내용은 무엇인가요?",
    content:
      " 초청건은 대부분의 경비 ( 그린피 / 카트 / 캐디피 / 식음료 ) 를 초청자분이 부담하는 무료 라운드를 원칙으로 합니다.",
  },
  {
    title: "라운드 시, 당부 말씀",
    content:
      " 본인의 플레이만을 생각하거나 , 지나친 내기 , 비매너 플레이 등을 지양해주세요. \n\n 서포터즈분들의 후기를 매칭 담당자가 초청자분께 확인 하기에 해당 사유 발생 시, 이후 초청 안내를 받으실 수 없습니다.",
  },
  {
    title: "프로필 사진 관리",
    content:
      " 서포터즈에 가입하신 분들께서는 원할한 매칭을 위하여 사진을 잘 관리 하여 주셨으면 합니다. \n\n 얼굴이 확인이 되는 사진을 1장 이상은 올려주시고 전신이 나온 사진도 올려주시기를 추천드립니다. \n\n 해당 프로필 정보는 매칭과 더 많은 초청 라운드를 위한 홍보에 사용 됨을 말씀드립니다. "},
];

export default function FAQ(props: { templates: any }) {
  return (
    <Continaer className="flex-1 page-pd-negative-all">
      <AccordionComponent
        tamplates={props.templates.map((t: any) => {
          return {
            ...t,
            title: (
              <div className="d-flex gap-1">
                <FaQuora
                  className="sm-mt"
                  //  size={20}
                />
                {t.title}
              </div>
            ),
          };
        })}
      />
    </Continaer>
  );
}

const Continaer = styled.div`
  color: black;
  white-space: pre-wrap;
  /* padding-top: 15px; */
  /* padding: 10px; */
  /* background-color: #c5e0b4; */
  .sm-mt {
    margin-top: 3px;
  }
`;
